import "./styles.css";
import LogoMaxDerby from "../../images/logos_grouped_white.png";
import Form from "../Form";

function Footer() {
   return (
      <div className="footer-site">
         <div className="footer-logos">
            <img
               className="footer-max-derby-logo"
               src={LogoMaxDerby}
               alt="Logo da Max Derby"
            />
            <div className="footer-contact txt-big txt-bold">
               {/* <a href="tel:40001581">
                  <span className="txt-white resa-bold">4000-1581</span>
               </a> */}
               {/* <a
                  href="https://www.maxplural.com.br"
                  target="_blank"
                  rel="noreferrer"
                  className="txt-white resa-bold"
               >
                  www.maxplural.com.br
               </a> */}
            </div>
         </div>
         <div className="footer-form">
            <Form />
         </div>
      </div>
   );
}

export default Footer;
