import icons from "../../configs/dataIcons";
import BannerCarousel from "../BannerCarousel";

import "./styles.css";

export default function Example() {
   return (
      <div className="apartment-details-icons txt-big txt-white mazz-bold">
         <BannerCarousel />
         <p className="apartment-details-title txt-bold txt-big txt-off-color">
            CONFIRA ALGUNS DIFERENCIAIS:
         </p>
         <div className="aparment-features">
            {icons.map((icon) => (
               <div className="apartment-item" key={icon.id}>
                  <img
                     className="apartment-item-icon"
                     src={icon.icon}
                     alt="icon"
                  />
                  <p className="apartment-feature txt-bold txt-normal">
                     {icon.text}
                  </p>
               </div>
            ))}
         </div>
      </div>
   );
}
