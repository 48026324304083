import LogoSite from "../../images/logo_site.png";
// import WaveIcon from "../../images/wave_icon.png";
// import PinIcon from "../../images/pin_icon.png";
import Form from "../Form";

import HeroImg from "../../images/hero_img.png";
// import LogosGrouped from "../../images/logos_grouped.png";

import "./styles.css";
import BannerPromo from "../BannerPromo";

function Header() {
   return (
      <div className="header-site">
         <div className="header-site-box">
            <div className="header-title-logo">
               <img className="header-logo" src={LogoSite} alt="logo do site" />
               <div className="header-main-title">
                  <p className="txt-main-color header-title txt-huge txt-bold">
                     VIVA O
                  </p>
                  <p className="txt-main-color header-title txt-huge txt-bold">
                     MELHOR DE
                  </p>
                  <p className="txt-main-color header-title txt-huge txt-bold">
                     CARNEIROS
                  </p>
                  <p
                     className="txt-off-color header-subtitle txt-normal"
                     id="form"
                  >
                     O SEU REFÚGIO COM <br /> VISTA PARA O MAR
                  </p>
               </div>
            </div>
            <Form />

            {/* <div className="header-info-box">
               <div className="header-top-info">
                  <div className="header-top-info-box">
                     <img
                        className="header-top-info-img"
                        src={WaveIcon}
                        alt=""
                     />
                     <p className="txt-accent-color header-top-info-text">
                        MAIS DE 600M²
                        <br />
                        DE ÁREA DE LAZER
                     </p>
                  </div>
                  <div className="header-top-info-box">
                     <img
                        className="header-top-info-img pin-icon"
                        src={PinIcon}
                        alt=""
                     />
                     <p className="txt-accent-color header-top-info-text">
                        A 250M
                        <br />
                        DO MAR
                     </p>
                  </div>
               </div>
               <div className="header-bot-info">
                  <span className="header-bot-title txt-big txt-bold">
                     1, 2, ou 3 QTS
                  </span>
                  <p className="header-bot-subtitle txt-bold">
                     UNIDADES COM JACUZZI <br />
                     OU PISCINA PRIVATIVA
                  </p>
               </div>
            </div> */}
         </div>
         <img className="header-hero-img" src={HeroImg} alt="" />
         {/* <img className="header-logos-img" src={LogosGrouped} alt=" " /> */}
         <BannerPromo />
      </div>
   );
}

export default Header;
