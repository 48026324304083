import "./styles.css";
import Carousel from "../Carousel";
import photos from "../../configs/dataPhotos";

function BannerCarousel() {
   return (
      <div className="apartment-container txt-orange">
         <div className="apartment-info">
            <div className="carousel-apartment-photos">
               <Carousel show={1}>
                  {photos.map((photo) => (
                     <div className="banner-carousel-box" key={photo.id}>
                        {photo.type === "img" ? (
                           <img
                              className="banner-carousel-img"
                              src={photo.photo}
                              alt="Imagem da Sala de Jantar"
                           />
                        ) : (
                           <video
                              controls
                              width="80%"
                              src={photo.photo}
                              autoPlay
                           ></video>
                        )}
                     </div>
                  ))}
               </Carousel>
            </div>
         </div>
      </div>
   );
}

export default BannerCarousel;
