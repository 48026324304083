import Maps from "../../images/maps.png";
import CashIcon from "../../images/cash_icon.png";
import LeisureIcon from "../../images/leisure_icon.png";
import HouseIcon from "../../images/house_icon.png";

import "./styles.css";

export default function MapLocalization() {
   return (
      <div className="maps-site">
         <a href="https://goo.gl/maps/mX7FNFFMLAUTVX2F8" target="__blank">
            <img className="maps-img" src={Maps} alt="Localização do Prédio" />
         </a>
         <div className="maps-information">
            {/* <div className="maps-bot-info">
               <img src={CashIcon} alt="" />
               <p className="txt-bold txt-white maps-bot-text">
                  MELHOR CUSTO <br />
                  BENEFÍCIO DA REGIÃO
               </p>
            </div> */}
            <div className="maps-bot-info">
               <img src={LeisureIcon} alt="" />
               <p className="txt-bold txt-white maps-bot-text">
                  MAIS DE 600M² <br />
                  DE ÁREA DE LAZER
               </p>
            </div>
            <div className="maps-bot-info">
               <img src={HouseIcon} alt="" />
               <p className="txt-bold txt-white maps-bot-text">
                  SUA CASA DE PRAIA COM <br />
                  CONFORTO DE HOTEL
               </p>
            </div>
         </div>
      </div>
   );
}
