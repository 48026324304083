import "./styles.css";

//import Form from "../Form";
import WaveIcon from "../../images/wave_icon.png";
import PinIcon from "../../images/pin_icon.png";
import LogosGrouped from "../../images/logos_grouped.png";

export default function BannerPromo() {
   return (
      <div className="banner-promo">
         {/* <div>div className="banner-promo-title">
            <h1 className="resa-bold txt-main-color uppercase txt-bigger">
               cadastre-se <br />
               para acompanhar <br /> todas as novidades <br />
               deste lançamento.
            </h1>
         </div> */}
         <div className="header-info-box">
            <div className="header-top-info">
               <div className="header-top-info-box">
                  <img className="header-top-info-img" src={WaveIcon} alt="" />
                  <p className="txt-accent-color header-top-info-text">
                     MAIS DE 600M²
                     <br />
                     DE ÁREA DE LAZER
                  </p>
               </div>
               <div className="header-top-info-box">
                  <img
                     className="header-top-info-img pin-icon"
                     src={PinIcon}
                     alt=""
                  />
                  <p className="txt-accent-color header-top-info-text">
                     A 250M
                     <br />
                     DO MAR
                  </p>
               </div>
            </div>
            <div className="header-bot-info">
               <span className="header-bot-title txt-big txt-bold">
                  1 à 4 quartos
               </span>
               <p className="header-bot-subtitle txt-bold">
                  UNIDADES COM JACUZZI <br />
                  OU PISCINA PRIVATIVA
               </p>
            </div>
            <img className="header-logos-img" src={LogosGrouped} alt=" " />
         </div>
         {/* <Form /> */}
      </div>
   );
}
