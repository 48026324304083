import icon1 from "../images/icon1.png";
import icon2 from "../images/icon2.png";
import icon3 from "../images/icon3.png";
import icon4 from "../images/icon4.png";
import icon5 from "../images/icon5.png";
import icon6 from "../images/icon6.png";
import icon7 from "../images/icon7.png";
import icon8 from "../images/icon8.png";
import icon9 from "../images/icon9.png";
import icon10 from "../images/icon10.png";
import icon11 from "../images/icon11.png";
import icon12 from "../images/icon12.png";
import icon13 from "../images/icon13.png";
import icon14 from "../images/icon14.png";
import icon15 from "../images/icon15.png";
import icon16 from "../images/icon16.png";

const icons = [
   {
      id: "1",
      icon: icon1,
      text: "ÁREA DE CONVIVÊNCIA",
   },
   {
      id: "2",
      icon: icon2,
      text: "PISCINA BABY",
   },
   {
      id: "3",
      icon: icon3,
      text: "ESPAÇO FITNESS",
   },
   {
      id: "4",
      icon: icon4,
      text: "BRINQUEDOTECA",
   },
   {
      id: "5",
      icon: icon5,
      text: "BEACH TENNIS",
   },
   {
      id: "6",
      icon: icon6,
      text: "LOUNGE",
   },
   {
      id: "7",
      icon: icon7,
      text: "BAR APOIO PISCINA",
   },
   {
      id: "8",
      icon: icon8,
      text: "PISCINA ADULTO COM HIDRO",
   },
   {
      id: "9",
      icon: icon9,
      text: "PET PLACE",
   },
   {
      id: "10",
      icon: icon10,
      text: "SELF MKT",
   },
   {
      id: "11",
      icon: icon11,
      text: "TERRAÇO SOLARIUM",
   },
   {
      id: "12",
      icon: icon12,
      text: "LIVING EXTERNO",
   },
   {
      id: "13",
      icon: icon13,
      text: "UNDER LOUNG",
   },
   {
      id: "14",
      icon: icon14,
      text: "SKY BAR",
   },
   {
      id: "15",
      icon: icon15,
      text: "DECK COM GAZEBOS",
   },
   {
      id: "16",
      icon: icon16,
      text: "HIDROS INDIVIDUAIS",
   },
];
export default icons;
